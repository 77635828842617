import { jwtDecode } from 'jwt-decode';
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useState,
  useRef
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { routes } from '@/utils/routes';
import useFirebase from '@/hooks/useFirebase';
import { getAccounts } from '@/services/account';
import { auth } from '@/services/firebase';
import { getCustomer } from '@/services/customer';
import { TCustomer } from '../types/Customer';
import { TDecodedTokenPayload } from '../types/Payload';
import { onAuthStateChanged } from 'firebase/auth';
import { TBusiness } from '../types/Business';
import {
  APP_IS_OPEN_LOCAL_STORAGE,
  MAIN_TAB_SESSION_STORAGE_NAME,
  REFRESHED_SESSION_STORAGE_NAME
} from '@/utils/browserStorageNames';

export const AuthContext = createContext<{
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  isLoggedIn: boolean;
  isForceLogout: boolean;
  setIsForceLogout: Dispatch<SetStateAction<boolean>>;
  onLogin: (token: string) => void;
  onLogout: () => void;
  accountId: string | undefined;
  business: TBusiness | undefined;
  customer: TCustomer | undefined;
} | null>(null);

const AuthProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isForceLogout, setIsForceLogout] = useState(false);
  const accountId = useRef<string>();
  const business = useRef<TBusiness>();
  const customer = useRef<TCustomer>();
  const { login, logout } = useFirebase();

  const getLoggedInUserDetails = async (token: string) => {
    const decoded: TDecodedTokenPayload & { claims: Record<string, string> } = jwtDecode(token);
    const loggedInAccountId = decoded.accountId ?? decoded.claims?.accountId;

    const response = await getAccounts();
    const accounts = response.data ?? [];
    const foundAccount = accounts.find((account) => account.id === loggedInAccountId);
    business.current = {
      id: foundAccount!.business.businessId,
      name: foundAccount!.business.businessName ?? ''
    };

    const customerRes = await getCustomer();
    customer.current = {
      id: customerRes.data.id,
      name: [customerRes.data.firstName, customerRes.data.lastName].join(' ') ?? '',
      email: customerRes?.data?.email,
      emailConfirmed: customerRes.data.emailConfirmed,
      preferredName: customerRes.data.preferredName
    };

    /* eslint-disable @typescript-eslint/no-explicit-any */
    (window as any).Intercom('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      name:
        customerRes.data.preferredName ??
        [customerRes.data.firstName, customerRes.data.lastName].join(' '),
      email: customerRes?.data?.email,
      user_id: `emerge-${customerRes.data.id}`,
      hide_default_launcher: true,
      customAttributes: {
        /* prettier-ignore */
        'Product': 'emerge'
      }
    });

    accountId.current = loggedInAccountId;
  };

  const handleLogin = useCallback(async (token: string) => {
    await login(token);
    setIsLoggedIn(true);
    navigate(routes.home);
  }, []);

  const handleLogout = useCallback(async () => {
    await logout();

    /* eslint-disable @typescript-eslint/no-explicit-any */
    (window as any).Intercom('shutdown');

    setIsForceLogout(true);
    setIsLoggedIn(false);
    accountId.current = undefined;
    business.current = undefined;
    customer.current = undefined;
    navigate(routes.base);
  }, []);

  useEffect(() => {
    const handleTabCloseLogout = async () => {
      await logout();
      setIsLoggedIn(false);
      navigate(routes.base);
    };

    const unregisterAuthObserver = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        const url = new URL(window.location.href);
        navigate({ pathname: routes.base, search: url.search });
        return;
      }

      // Check if the application is already open
      if (localStorage.getItem(APP_IS_OPEN_LOCAL_STORAGE)) {
        sessionStorage.setItem(REFRESHED_SESSION_STORAGE_NAME, 'true');
      } else {
        localStorage.setItem(APP_IS_OPEN_LOCAL_STORAGE, 'true');
        sessionStorage.setItem(MAIN_TAB_SESSION_STORAGE_NAME, 'true');

        window.addEventListener('beforeunload', function () {
          localStorage.removeItem(APP_IS_OPEN_LOCAL_STORAGE);
        });
      }

      if (
        !sessionStorage.getItem(REFRESHED_SESSION_STORAGE_NAME) ||
        sessionStorage.getItem(REFRESHED_SESSION_STORAGE_NAME) == 'false'
      ) {
        handleTabCloseLogout();
        return;
      }

      sessionStorage.setItem(REFRESHED_SESSION_STORAGE_NAME, 'true');
      setIsLoggedIn(true);
      setIsForceLogout(false);
      await getLoggedInUserDetails(await user!.getIdToken());
      const url = new URL(window.location.href);

      navigate({
        pathname:
          location.pathname !== routes.base &&
          (Object.values(routes) as string[]).includes(location.pathname)
            ? location.pathname
            : routes.home,
        search: url.search
      });
    });
    return () => {
      unregisterAuthObserver();
      if (sessionStorage.getItem(MAIN_TAB_SESSION_STORAGE_NAME) == 'true') {
        window.removeEventListener('beforeunload', function () {
          localStorage.removeItem(APP_IS_OPEN_LOCAL_STORAGE);
        });
      }
    };
  }, []);

  const value = {
    isLoggedIn,
    isForceLogout,
    setIsForceLogout,
    onLogin: handleLogin,
    onLogout: handleLogout,
    accountId: accountId.current,
    setIsLoggedIn,
    business: business.current,
    customer: customer.current
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
