import { signIn, signOut } from '@/services/firebase';
import { useCallback } from 'react';

const useFirebase = () => {
  const login = useCallback(async (token: string) => {
    await signIn(token);
  }, []);

  const logout = useCallback(async () => {
    await signOut();
  }, []);

  return { login, logout };
};

export default useFirebase;
