import React, { createContext, HTMLProps, useState, Dispatch, SetStateAction } from 'react';
import { TSelectOptions } from '@/components/Select';
import { TAllAccounts, TWalletData } from '@/features/home/types/WalletData';
import { TTransactionHistory } from '@/features/home/types/TransactionHistory';
import { TAccountCard } from '@/features/home/types/Card';
import {
  TAttachmentType,
  TGetTransactionDetailsData,
  TGetTransactionsData
} from '@/services/types/TransactionData';
import { TBatchPaymentBankAccount } from '../types/BatchPayments';
import { TTeamMember, TTransactionGeneralLedgerCode } from '../types/Business';
import { NotificationType, TNotificationState } from '../types/Notification';
import { EnumValues } from '@/services/types/EnumValues';
import { TDateOption } from '@/features/home/hooks/useDateFilter';

export const ALL_ACCOUNTS = 'All accounts';

export type TDate = Date | null;

export const HomeProviderContext = createContext<{
  bankAccount: TWalletData | undefined;
  setBankAccount: Dispatch<SetStateAction<TWalletData | undefined>>;
  selectedAccount: TSelectOptions;
  setSelectedAccount: Dispatch<SetStateAction<TSelectOptions>>;
  defaultOption: TSelectOptions;
  selectedTransaction: TTransactionHistory | undefined;
  setSelectedTransaction: Dispatch<SetStateAction<TTransactionHistory | undefined>>;
  setAttachmentUrl: Dispatch<SetStateAction<string | undefined>>;
  attachmentUrl: string | undefined;
  setAttachmentType: Dispatch<SetStateAction<EnumValues<typeof TAttachmentType> | undefined>>;
  attachmentType: EnumValues<typeof TAttachmentType> | undefined;
  dateFilter: { startDate: TDate; endDate: TDate; rangeName: TDateOption } | undefined;
  setDateFilter: Dispatch<
    SetStateAction<{ startDate: TDate; endDate: TDate; rangeName: TDateOption } | undefined>
  >;
  setTransactionFilter: Dispatch<SetStateAction<(string | number)[]>>;
  transactionFilter: (string | number)[];
  setTeamMemberFilter: Dispatch<SetStateAction<(string | number)[]>>;
  teamMemberFilter: (string | number)[];
  setAccountsFilter: Dispatch<SetStateAction<(string | number)[]>>;
  accountsFilter: (string | number)[];
  allAccountsBalance: number | undefined;
  setAllAccountsBalance: Dispatch<SetStateAction<number | undefined>>;
  allAccounts: TAllAccounts;
  setAllAccounts: Dispatch<SetStateAction<TAllAccounts>>;
  wallets: TWalletData[];
  setWallets: Dispatch<SetStateAction<TWalletData[]>>;
  transactions: TTransactionHistory[];
  setTransactions: Dispatch<SetStateAction<TTransactionHistory[]>>;
  cards: TAccountCard[];
  setCards: Dispatch<SetStateAction<TAccountCard[]>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setFocusFilter: Dispatch<SetStateAction<string | undefined>>;
  focusFilter: string | undefined;
  rawTransactions: TTransactionHistory[];
  setRawTransactions: Dispatch<SetStateAction<TTransactionHistory[]>>;
  rawTransactionDetails: TGetTransactionDetailsData[];
  setRawTransactionDetails: Dispatch<SetStateAction<TGetTransactionDetailsData[]>>;
  setError: Dispatch<SetStateAction<string | undefined>>;
  error: string | undefined;
  teamMembers: TTeamMember[];
  setTeamMembers: Dispatch<SetStateAction<TTeamMember[]>>;
  transactionGeneralLedgerCodes: TTransactionGeneralLedgerCode[];
  setTransactionGeneralLedgerCodes: Dispatch<SetStateAction<TTransactionGeneralLedgerCode[]>>;
  generalLedgerCodeSearchInput: string;
  setGeneralLedgerCodeSearchInput: Dispatch<SetStateAction<string>>;
  originalTransactionGeneralLedgerCodes: TTransactionGeneralLedgerCode[];
  setOriginalTransactionGeneralLedgerCodes: Dispatch<
    SetStateAction<TTransactionGeneralLedgerCode[]>
  >;
  archivedTransactionGeneralLedgerCodes: TTransactionGeneralLedgerCode[];
  setArchivedTransactionGeneralLedgerCodes: Dispatch<
    SetStateAction<TTransactionGeneralLedgerCode[]>
  >;
  setNewBatchPayments: Dispatch<
    SetStateAction<{
      csvFileName: string | null;
      rows: TBatchPaymentBankAccount[];
    }>
  >;
  newBatchPayments: {
    csvFileName: string | null;
    rows: TBatchPaymentBankAccount[];
  };
  triggerNotification: (type: NotificationType, data?: Record<string, string>) => void;
  setNotification: Dispatch<SetStateAction<TNotificationState | undefined>>;
  notification: TNotificationState | undefined;
  clearHomeContext: () => void;
  transactionSnapshot: TTransactionHistory[];
  setTransactionSnapshot: Dispatch<SetStateAction<TTransactionHistory[]>>;
  setExportInProgress: Dispatch<SetStateAction<boolean>>;
  exportInProgress: boolean;
} | null>(null);

const HomeProvider: React.FC<HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [wallets, setWallets] = useState<TWalletData[]>([]);
  const [cards, setCards] = useState<TAccountCard[]>([]);
  const [transactions, setTransactions] = useState<TTransactionHistory[]>([]);
  const [transactionSnapshot, setTransactionSnapshot] = useState<TTransactionHistory[]>([]);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [allAccountsBalance, setAllAccountsBalance] = useState<number>();
  const [allAccounts, setAllAccounts] = useState<TWalletData>({
    id: 'DEFAULT_WALLET',
    name: ALL_ACCOUNTS,
    accountNumber: '',
    balance: undefined
  });
  const defaultOption = {
    label: ALL_ACCOUNTS,
    labelDescription: String(allAccountsBalance),
    value: ALL_ACCOUNTS
  };
  const [isLoading, setIsLoading] = useState(true);
  const [transactionFilter, setTransactionFilter] = useState<(string | number)[]>([]);
  const [teamMemberFilter, setTeamMemberFilter] = useState<(string | number)[]>([]);
  const [accountsFilter, setAccountsFilter] = useState<(string | number)[]>([]);
  const [dateFilter, setDateFilter] = useState<{
    startDate: TDate;
    endDate: TDate;
    rangeName: TDateOption;
  }>();
  const [selectedAccount, setSelectedAccount] = useState<TSelectOptions>(defaultOption);
  const [bankAccount, setBankAccount] = useState<TWalletData>();
  const [focusFilter, setFocusFilter] = useState<string>();
  const [rawTransactions, setRawTransactions] = useState<TGetTransactionsData[]>([]);
  const [rawTransactionDetails, setRawTransactionDetails] = useState<TGetTransactionDetailsData[]>(
    []
  );
  const [newBatchPayments, setNewBatchPayments] = useState<{
    csvFileName: string | null;
    rows: TBatchPaymentBankAccount[];
  }>({
    csvFileName: null,
    rows: []
  });

  const [teamMembers, setTeamMembers] = useState<TTeamMember[]>([]);
  const [transactionGeneralLedgerCodes, setTransactionGeneralLedgerCodes] = useState<
    TTransactionGeneralLedgerCode[]
  >([]);
  const [originalTransactionGeneralLedgerCodes, setOriginalTransactionGeneralLedgerCodes] =
    useState<TTransactionGeneralLedgerCode[]>([]);
  const [archivedTransactionGeneralLedgerCodes, setArchivedTransactionGeneralLedgerCodes] =
    useState<TTransactionGeneralLedgerCode[]>([]);
  const [generalLedgerCodeSearchInput, setGeneralLedgerCodeSearchInput] = useState<string>('');

  const [selectedTransaction, setSelectedTransaction] = useState<TTransactionHistory>();
  const [attachmentUrl, setAttachmentUrl] = useState<string>();
  const [attachmentType, setAttachmentType] = useState<EnumValues<typeof TAttachmentType>>();

  const [error, setError] = useState<string>();
  const [notification, setNotification] = useState<TNotificationState>();

  const triggerNotification = (type: NotificationType, data?: Record<string, string>) => {
    setNotification({ type, data });
  };

  const clearHomeContext = () => {
    setWallets([]);
    setCards([]);
    setTransactions([]);
    setTeamMembers([]);
    setTransactionGeneralLedgerCodes([]);
    setGeneralLedgerCodeSearchInput('');
    setOriginalTransactionGeneralLedgerCodes([]);
    setArchivedTransactionGeneralLedgerCodes([]);
    setAllAccounts({
      id: 'DEFAULT_WALLET',
      name: ALL_ACCOUNTS,
      accountNumber: '',
      balance: undefined
    });
    setAllAccountsBalance(undefined);
    setTransactionFilter([]);
    setTeamMemberFilter([]);
    setAccountsFilter([]);
    setDateFilter(undefined);
    setSelectedAccount(defaultOption);
    setBankAccount(undefined);
    setFocusFilter(undefined);
    setRawTransactionDetails([]);
    setSelectedTransaction(undefined);
    setAttachmentUrl(undefined);
    setAttachmentType(undefined);
    setTransactionSnapshot([]);
    setExportInProgress(false);
  };

  const value = {
    bankAccount,
    setBankAccount,
    selectedAccount,
    setSelectedAccount,
    defaultOption,
    selectedTransaction,
    setSelectedTransaction,
    attachmentUrl,
    setAttachmentUrl,
    attachmentType,
    setAttachmentType,
    setDateFilter,
    dateFilter,
    setTransactionFilter,
    setTeamMemberFilter,
    setAccountsFilter,
    transactionFilter,
    teamMemberFilter,
    accountsFilter,
    allAccountsBalance,
    setAllAccountsBalance,
    allAccounts,
    setAllAccounts,
    setWallets,
    wallets,
    setTransactions,
    transactions,
    setIsLoading,
    isLoading,
    setCards,
    cards,
    setFocusFilter,
    focusFilter,
    rawTransactions,
    setRawTransactions,
    rawTransactionDetails,
    setRawTransactionDetails,
    setError,
    error,
    setTeamMembers,
    teamMembers,
    setTransactionGeneralLedgerCodes,
    transactionGeneralLedgerCodes,
    originalTransactionGeneralLedgerCodes,
    setOriginalTransactionGeneralLedgerCodes,
    archivedTransactionGeneralLedgerCodes,
    setArchivedTransactionGeneralLedgerCodes,
    generalLedgerCodeSearchInput,
    setGeneralLedgerCodeSearchInput,
    newBatchPayments,
    setNewBatchPayments,
    clearHomeContext,
    notification,
    triggerNotification,
    setNotification,
    setTransactionSnapshot,
    transactionSnapshot,
    setExportInProgress,
    exportInProgress
  };

  return <HomeProviderContext.Provider value={value}>{children}</HomeProviderContext.Provider>;
};

export default HomeProvider;
