import { lazy } from 'react';

type ComponentImportType = () => Promise<{ default: React.ComponentType }>;

const sessionKey = 'lazyWithRetry';

const lazyWithRetry = (componentImport: ComponentImportType, name: string) => {
  return lazy(async () => {
    const hasRefreshed = globalThis.sessionStorage.getItem(`${sessionKey}-${name}`) || 'false';

    try {
      globalThis.sessionStorage.setItem(`${sessionKey}-${name}`, 'false');
      return await componentImport();
    } catch (error) {
      if (hasRefreshed === 'false') {
        globalThis.sessionStorage.setItem(`${sessionKey}-${name}`, 'true');
        globalThis.location.reload();
      }

      if (hasRefreshed === 'true') throw new Error('chunkLoadError');
    }
    return await componentImport();
  });
};

export default lazyWithRetry;
