import 'flowbite/dist/flowbite.css';
import './styles/index.css';

import React, { lazy, Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

const LoadingScreen = lazy(() => import('@/pages/common/LoadingScreen'));

root.render(
  <Suspense fallback={<LoadingScreen />}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>
);
