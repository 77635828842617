import { REFRESHED_SESSION_STORAGE_NAME } from '@/utils/browserStorageNames';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getFirestore, doc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);

const auth = getAuth();
const db = getFirestore();
const analytics = getAnalytics(app);

const clearSessionStorage = () => {
  sessionStorage.clear();
  localStorage.clear();
};

const signIn = async (token: string) => {
  try {
    sessionStorage.setItem(REFRESHED_SESSION_STORAGE_NAME, 'true');
    await signInWithCustomToken(auth, token)
      .then(() => {
        // signed in!
      })
      .catch((error: unknown) => {
        console.error(error);
      });
  } catch (err: unknown) {
    console.error(err);
    throw err;
  }
};

const signOut = async () => {
  clearSessionStorage();
  await auth.signOut();
};

const getUserToken = async () => {
  return auth.currentUser ? await auth.currentUser.getIdToken(false) : null;
};

const logAnalyticsEvent = (name: string, options?: Record<string, string | boolean>) =>
  logEvent(analytics, name, options);

const webSessionRef = (sessionId: string) => doc(db, 'web/live-updates/sessions', sessionId);

export { auth, signIn, signOut, getUserToken, db, webSessionRef, logAnalyticsEvent };
