import { logAnalyticsEvent } from '../firebase';
import { ResponseBody } from '../types/ResponseBody';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const handleApiError = async (responseError: any) => {
  if (typeof responseError?.then === 'function') {
    await responseError.then((errResponse: ResponseBody<string>) => {
      logAnalyticsEvent('exception', {
        description: `Status ${errResponse.status} with code ${errResponse.code}: ${errResponse.data}`,
        fatal: false
      });
      throw errResponse;
    });
  }

  logAnalyticsEvent('exception', {
    description: responseError,
    fatal: false
  });
  throw responseError;
};
