import React, { Dispatch, SetStateAction, createContext, useState, useCallback } from 'react';
import { IGetXeroTenantResponse, XeroTenantFeedStateEnum } from '@/services/types/Xero';
import { EnumValues } from '@/services/types/EnumValues';

export type TTenantFeed = {
  id: string;
  walletId: string;
  accountName: string;
  accountNumber: string;
  accountBalance: string;
  feedStatus: string;
  lastSync: string;
  state: EnumValues<typeof XeroTenantFeedStateEnum>;
};

export const XeroContext = createContext<{
  setXeroTenant: Dispatch<SetStateAction<IGetXeroTenantResponse | undefined>>;
  xeroTenant: IGetXeroTenantResponse | undefined;
  isXeroConnected: boolean;
  setIsXeroConnected: Dispatch<SetStateAction<boolean>>;
  xeroConnection: string | undefined;
  setXeroConnection: Dispatch<SetStateAction<string | undefined>>;
  isFirstConnection: boolean;
  setIsFirstConnection: Dispatch<SetStateAction<boolean>>;
  isSelectingFeed: boolean;
  setIsSelectingFeed: Dispatch<SetStateAction<boolean>>;
  existingXeroFeeds: TTenantFeed[];
  setExistingXeroFeeds: Dispatch<SetStateAction<TTenantFeed[]>>;
  allBankAccountsForXero: TTenantFeed[];
  setAllBankAccountsForXero: Dispatch<SetStateAction<TTenantFeed[]>>;
  setHasExistingConnection: Dispatch<SetStateAction<boolean>>;
  hasExistingConnection: boolean;
  clearXeroContext: () => void;
  isXeroAuthoriser: boolean;
  setIsXeroAuthoriser: Dispatch<SetStateAction<boolean>>;
  setHasMissingScope: Dispatch<SetStateAction<boolean>>;
  hasMissingScope: boolean;
  setXeroAuthoriserName: Dispatch<SetStateAction<string>>;
  xeroAuthoriserName: string;
} | null>(null);

const XeroProvider: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children }) => {
  const [xeroTenant, setXeroTenant] = useState<IGetXeroTenantResponse>();
  const [xeroConnection, setXeroConnection] = useState<string>();
  const [isXeroConnected, setIsXeroConnected] = useState(true);
  const [isFirstConnection, setIsFirstConnection] = useState(false);
  const [isSelectingFeed, setIsSelectingFeed] = useState(false);
  const [existingXeroFeeds, setExistingXeroFeeds] = useState<TTenantFeed[]>([]);
  const [allBankAccountsForXero, setAllBankAccountsForXero] = useState<TTenantFeed[]>([]);
  const [hasExistingConnection, setHasExistingConnection] = useState(false);
  const [isXeroAuthoriser, setIsXeroAuthoriser] = useState(false);
  const [hasMissingScope, setHasMissingScope] = useState(false);
  const [xeroAuthoriserName, setXeroAuthoriserName] = useState('');

  const clearXeroContext = useCallback(() => {
    setXeroConnection(undefined);
    setXeroTenant(undefined);
    setIsXeroConnected(false);
    setIsFirstConnection(false);
    setIsSelectingFeed(false);
    setExistingXeroFeeds([]);
    setAllBankAccountsForXero([]);
    setHasExistingConnection(false);
    setIsXeroAuthoriser(false);
    setHasMissingScope(false);
    setXeroAuthoriserName('');
  }, []);

  const value = {
    xeroTenant,
    setXeroTenant,
    isXeroConnected,
    setIsXeroConnected,
    setXeroConnection,
    xeroConnection,
    isFirstConnection,
    setIsFirstConnection,
    isSelectingFeed,
    setIsSelectingFeed,
    existingXeroFeeds,
    setExistingXeroFeeds,
    allBankAccountsForXero,
    setAllBankAccountsForXero,
    hasExistingConnection,
    setHasExistingConnection,
    clearXeroContext,
    isXeroAuthoriser,
    setIsXeroAuthoriser,
    hasMissingScope,
    setHasMissingScope,
    xeroAuthoriserName,
    setXeroAuthoriserName
  };

  return <XeroContext.Provider value={value}>{children}</XeroContext.Provider>;
};

export default XeroProvider;
