import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import AuthProvider from '@/context/AuthProvider';
import XeroProvider from '@/context/XeroProvider';
import HomeContextProvider from '@/context/HomeProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { routes } from './utils/routes';
import lazyWithRetry from './utils/lazyWithRetry';
import { KEEP_SEARCH_PARAMS_SESSION_STORAGE_NAME } from './utils/browserStorageNames';

const queryClient = new QueryClient();

const LoginPage = lazyWithRetry(() => import('@/pages/Login'), 'login');
const HomePage = lazyWithRetry(() => import('@/pages/Home'), 'main_home');
const EmergeHomePage = lazyWithRetry(() => import('@/pages/Home/EmergeHome'), 'emerge_home');
const XeroIntegrationPage = lazyWithRetry(
  () => import('@/pages/Home/XeroIntegration'),
  'xero_home'
);
const BatchPaymentPage = lazyWithRetry(
  () => import('@/pages/Home/BatchPayment'),
  'batch_payments_home'
);
const NewBatchPaymentPage = lazyWithRetry(
  () => import('@/pages/Home/NewBatchPayment'),
  'new_batch_payment'
);
const ViewBatchPaymentPage = lazyWithRetry(
  () => import('@/pages/Home/ViewBatchPayment'),
  'view_batch_payment'
);
const GeneralLedgerCodeSettingsPage = lazyWithRetry(
  () => import('@/pages/Home/GeneralLedgerCodeSettings'),
  'general_ledger_settings'
);
const PageNotFound = lazyWithRetry(() => import('@/pages/common/PageNotFound'), 'page_not_found');

const App = () => {
  const navigate = useNavigate();
  const handleUnhandledRejection = (err: unknown) => {
    console.error(err);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    if (
      url.search &&
      (sessionStorage.getItem(KEEP_SEARCH_PARAMS_SESSION_STORAGE_NAME) == 'false' ||
        !sessionStorage.getItem(KEEP_SEARCH_PARAMS_SESSION_STORAGE_NAME))
    ) {
      sessionStorage.setItem(KEEP_SEARCH_PARAMS_SESSION_STORAGE_NAME, 'true');
      navigate({ pathname: routes.base, search: url.search });
    }

    window.addEventListener('unhandledrejection', handleUnhandledRejection);
    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);
  return (
    <div id="App">
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <XeroProvider>
            <HomeContextProvider>
              <div className="max-w-full h-full w-full font-sans">
                <Routes>
                  <Route index element={<LoginPage />} />
                  <Route path={routes.home} element={<HomePage />}>
                    <Route index element={<EmergeHomePage />} />
                    <Route path={routes.xero} element={<XeroIntegrationPage />} />
                    <Route path={routes.batchPayments}>
                      <Route index element={<BatchPaymentPage />} />
                      <Route path={routes.newBatchPayments} element={<NewBatchPaymentPage />} />
                      <Route path={routes.viewBatchPayments} element={<ViewBatchPaymentPage />} />
                    </Route>
                    <Route
                      path={routes.generalLedgerSettings}
                      element={<GeneralLedgerCodeSettingsPage />}
                    />
                  </Route>
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </div>
            </HomeContextProvider>
          </XeroProvider>
        </AuthProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
